<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0011 19.8995L11.294 20.6066C11.6845 20.9971 12.3176 20.9971 12.7082 20.6066L12.0011 19.8995ZM16.2437 5.75736C18.5868 8.1005 18.5868 11.8995 16.2437 14.2426L17.6579 15.6569C20.7821 12.5327 20.7821 7.46734 17.6579 4.34315L16.2437 5.75736ZM7.75842 5.75736C10.1016 3.41421 13.9006 3.41421 16.2437 5.75736L17.6579 4.34315C14.5337 1.21895 9.4684 1.21895 6.3442 4.34315L7.75842 5.75736ZM7.75842 14.2426C5.41527 11.8995 5.41527 8.10051 7.75842 5.75736L6.3442 4.34315C3.22001 7.46734 3.22001 12.5327 6.3442 15.6569L7.75842 14.2426ZM12.7082 19.1924L7.75842 14.2426L6.3442 15.6569L11.294 20.6066L12.7082 19.1924ZM16.2437 14.2426L11.294 19.1924L12.7082 20.6066L17.6579 15.6569L16.2437 14.2426Z"
      :fill="getBaseColor(darkMode)"
    />
    <circle
      cx="12"
      cy="10"
      r="2"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
